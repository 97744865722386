'use client';
import { Key, useEffect, useState } from 'react';
import { Button, Input, Popover, PopoverContent, PopoverTrigger, Select, SelectItem, Tooltip } from '@nextui-org/react';
import { useRouter } from 'next/navigation';
import { Loading } from '../Loading';

const SearchBar = ({placeHolder}: {placeHolder?: string}) => {
    const router = useRouter();
    const [input, setInput] = useState('');
    const [placeholder, setPlaceholder] = useState(placeHolder ? placeHolder : 'Search for companies or scam reports');
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState('report');
    const [isOpen, setIsOpen] = useState(false);

    const handleSearchInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setInput(e.target.value);
    };

    const handleSearch = () => {
        if (input.length > 2) {
            // if (category == 'report') {
            //     router.push(`/scam-reports?search=${input}&page=1`);
            // } else if (category == 'company') {
            //     router.push(`/companies?search=${input}&page=1`);
            // }
            router.push(`/search?q=${input}&page=1`);
        } else {
            setIsOpen(true);
        }
    };

    if (loading) return <Loading/>;

    return (
        <div className="w-full max-w-lg flex flex-col justify-center sm:flex-row ">
            {/* <div className="flex min-w-[150px] max-w-[600px] flex-col gap-2 rounded-md bg-white px-2 py-1 text-base font-semibold sm:flex-grow"> */}
                <Input
                    type="text"
                    placeholder={placeholder}
                    size="lg"
                    onChange={handleSearchInput}
                    onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            handleSearch();
                        }
                    }}
                    endContent={
                        <Popover isOpen={isOpen} onOpenChange={(open) => setIsOpen(open)}>
                            <PopoverTrigger>
                                <Button onClick={handleSearch} isIconOnly className="min-w-unit-12 md:min-w-unit-16" color='primary'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.5 18.5L22 22" stroke="#ffffff" width="2" strokeLinecap="round" />
                                        <path
                                            d="M6.75 3.27093C8.14732 2.46262 9.76964 2 11.5 2C16.7467 2 21 6.25329 21 11.5C21 16.7467 16.7467 21 11.5 21C6.25329 21 2 16.7467 2 11.5C2 9.76964 2.46262 8.14732 3.27093 6.75"
                                            stroke="#ffffff"
                                            width="2"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </Button>
                            </PopoverTrigger>
                            {input.length < 3 && (
                                <PopoverContent>
                                    <div className="px-1 py-2">
                                        <div className="text-small font-semibold">
                                            Search query must be longer than <span className="text-primary">2</span> characters
                                        </div>
                                    </div>
                                </PopoverContent>
                            )}
                        </Popover>
                    }
                />
            {/* <div className="flex flex-grow items-center gap-3">
                    <input
                        type="text"
                        placeholder={placeholder}
                        className="min-h-[50px] min-w-[180px] flex-grow border-0 bg-transparent text-black outline-0"
                        value={input}
                        onChange={handleSearchInput}
                        onKeyDown={async (e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSearch();
                            }
                        }}
                    />
                    <Popover isOpen={isOpen} onOpenChange={(open) => setIsOpen(open)}>
                        <PopoverTrigger>
                            <Button type="button" onClick={handleSearch} isIconOnly className="min-w-unit-12 !bg-primary text-white md:min-w-unit-20">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.5 18.5L22 22" stroke="#ffffff" width="1.5" strokeLinecap="round" />
                                    <path
                                        d="M6.75 3.27093C8.14732 2.46262 9.76964 2 11.5 2C16.7467 2 21 6.25329 21 11.5C21 16.7467 16.7467 21 11.5 21C6.25329 21 2 16.7467 2 11.5C2 9.76964 2.46262 8.14732 3.27093 6.75"
                                        stroke="#ffffff"
                                        width="1.5"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            </Button>
                        </PopoverTrigger>
                        {input.length < 3 && (
                            <PopoverContent>
                                <div className="px-1 py-2">
                                    <div className="text-small font-semibold">
                                        Search query must be longer than <span className="text-primary">2</span> characters
                                    </div>
                                </div>
                            </PopoverContent>
                        )}
                    </Popover>
                </div> */}
        </div>
        // </div>
    );
};

export default SearchBar;
